import React from 'react'
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import BackgroundImg from 'gatsby-background-image'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Layout from '../../../../../../components/layout'
import SEO from '../../../../../../components/seo'
import Heading from '../../../../../../components/heading'
import HeroBox from '../../../../../../components/herobox'
import { ButtonBase } from '@material-ui/core'
import orange from '@material-ui/core/colors/orange'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        minHeight: '60vh',
        padding: '15px',
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    wrapper: {
        minHeight: '60vh',
        padding: '25px',
        // backgroundColor: 'rgba(0,0,0,.3)',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    // hero: {
    //     padding: '1rem',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     flexDirection: 'column',
    //     minHeight: '80vh',
    //     textAlign: 'center',
    //     color: '#ffffff',
    // },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    heading2s: {
        // color: '#48bd98',
        // marginTop: '5rem',
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            top: '-.1em',
            verticalAlign: 'middle',
            height: '1em',
            borderLeftColor: '#48bd98',
            borderLeftWidth: '7px',
            borderLeftStyle: 'solid',
            marginRight: '10px',
        }
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    // Card
    cardRoot: {
        display: 'flex',

    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flex: '1 0 auto',
    },
    //Grid
    gridRoot: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
    },
    gridGroup2: {
        padding: '0 30px',
    },
    gridPaper: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridPaper2: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridImage: {
        width: 250,
        // height: 150,
    },
    gridImage2: {
        width: 350,
        // height: 150,
    },
    gridImg: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    gridImg2: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    //Stepper
    stepperRoot: {
        width: '100%',
    },
    stepperButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        }
    },
    stepperButtonTxt: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
        marginBottom: '2rem',
    },
    stLabel: {
        padding: '1px',
        backgroundColor: orange[600],
        color: '#ffffff',
        borderRadius: '2px',
    },
    //Mobile Stepper
    msRoot: {
        maxWidth: 500,
        flexGrow: 1,
    },
    msHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        // paddingLeft: theme.spacing(4),
        // backgroundColor: theme.palette.background.default,
    },
    msBody: {
        // display: 'flex',
        // alignItems: 'center',
        padding: '15px'
    },
    msImg: {
        // height: 255,
        maxWidth: 1000,
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        marginBottom: '0',
    },
    msGridItem: {
        marginTop: '5rem',
    },
    msButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    // Alert
    alertContainer: {
        // width: '100%',
        marginTop: '2rem',
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    },
    // Img
    imageContainer: {
        margin: '15px',
        '& > *': {
            borderRadius: '3px',
        }
    },
}));

const IsawaonsenStationAshiyuPage = ({ data }) => {
    const classes = useStyles();
    const notChild = false;
    const child = true;
    const language = 'zh_hans';
    const slug = 'station/isawaonsen/onsen/ashiyu';
    const pageTitle = '免费泡脚温泉';
    const descriptions = [
        '轻轻松松、怡然自得。',
        '泡个脚休息一下'
    ];
    
    /** MobileStepper 1 */
    const slides1 = [
        {
            label: '足汤怎麽去',
            imgPath: data.D0.childImageSharp.fluid.src,
        },
        {
            label: '出檢票口後右轉',
            imgPath: data.D1.childImageSharp.fluid.src,
        },
        {
            label: '從左手邊的電動扶梯下樓',
            imgPath: data.D2.childImageSharp.fluid.src,
        },
        {
            label: '下到一樓後180度右轉',
            imgPath: data.D3.childImageSharp.fluid.src,
        },
        {
            label: '沿着这栋建筑物直走',
            imgPath: data.D4.childImageSharp.fluid.src,
        },
        {
            label: '免费泡脚温泉',
            imgPath: data.D5.childImageSharp.fluid.src,
        }
    ];
    const maxSlides1 = slides1.length;
    const [activeSlides1, setActiveSlides1] = React.useState(0);
    const handleNextSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 + 1);
    }
    const handleBackSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 -1);
    }
    /** MobileStepper 2 */
    const slides2 = [
        {
            label: '',
            imgPath: data.ashiyu1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.ashiyu3.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.towel.childImageSharp.fluid.src,
        }
    ];
    const maxSlides2 = slides2.length;
    const [activeSlides2, setActiveSlides2] = React.useState(0);
    const handleNextSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 + 1);
    }
    const handleBackSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 -1);
    }
    /** MobileStepper 3 */
    const slides3 = [
        {
            label: '',
            imgPath: data.bending.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.cola.childImageSharp.fluid.src,
        }
    ];
    const maxSlides3 = slides3.length;
    const [activeSlides3, setActiveSlides3] = React.useState(0);
    const handleNextSlides3 = () => {
        setActiveSlides3(prevActiveSlides3 => prevActiveSlides3 + 1);
    }
    const handleBackSlides3 = () => {
        setActiveSlides3(prevActiveSlides3 => prevActiveSlides3 -1);
    }
    /** MobileStepper 4 */
    const slides4 = [
        {
            label: '',
            imgPath: data.onsen1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.onsen2.childImageSharp.fluid.src,
        }
    ];
    const maxSlides4 = slides4.length;
    const [activeSlides4, setActiveSlides4] = React.useState(0);
    const handleNextSlides4 = () => {
        setActiveSlides4(prevActiveSlides4 => prevActiveSlides4 + 1);
    }
    const handleBackSlides4 = () => {
        setActiveSlides4(prevActiveSlides4 => prevActiveSlides4 -1);
    }
    /** MobileStepper 5 */
    const slides5 = [
        {
            label: '',
            imgPath: data.hiroba1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.hiroba2.childImageSharp.fluid.src,
        }
    ];
    const maxSlides5 = slides5.length;
    const [activeSlides5, setActiveSlides5] = React.useState(0);
    const handleNextSlides5 = () => {
        setActiveSlides5(prevActiveSlides5 => prevActiveSlides5 + 1);
    }
    const handleBackSlides5 = () => {
        setActiveSlides5(prevActiveSlides5 => prevActiveSlides5 -1);
    }
    /** MobileStepper 6 */
    const slides6 = [
        {
            label: '',
            imgPath: data.hotel1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.hotel2.childImageSharp.fluid.src,
        }
    ];
    const maxSlides6 = slides6.length;
    const [activeSlides6, setActiveSlides6] = React.useState(0);
    const handleNextSlides6 = () => {
        setActiveSlides6(prevActiveSlides6 => prevActiveSlides6 + 1);
    }
    const handleBackSlides6 = () => {
        setActiveSlides6(prevActiveSlides6 => prevActiveSlides6 -1);
    }
    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle} />
            <BackgroundImg
                Tag="section"
                fluid={data.hero.childImageSharp.fluid}
                backgourndColor={`#48bd98`}
            >
                <section className={classes.hero}><div className={classes.wrapper}>
                    <Typography className={classes.headerH3} variant="h3" content="h3">旁边就是前往富士山的巴士站</Typography>
                    <Typography className={classes.HeaderH2} variant="h2" content="h2">{pageTitle}</Typography>
                    <div className={classes.paragraphs}>
                        {
                            descriptions.map((description, i) => (
                                <p className={classes.paragraph} key={i}><span className={classes.span}>{description}</span></p>
                            ))
                        }
                    </div>
                </div></section>
            </BackgroundImg>
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>旁边就是前往富士山的巴士站</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>免费泡脚温泉</p>
                                        <p>在观光服务处喝过葡萄酒之后，顺便买条100日圆的毛巾来泡个脚。 </p>
                                        <ul>
                                            <li>天然温热效果，消除舟车劳顿。</li>
                                            <li>促进血液循环，全身上下温暖。</li>
                                            <li>心脏负担较低，适合老人小孩。</li>
                                        </ul>
                                        <div className={classes.alertContainer}>
                                            <Alert severity="error">
                                                泡脚时请勿踢水、喝酒或大声喧哗。
                                            </Alert>
                                        </div>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides1[activeSlides1].imgPath}
                                        alt={slides1[activeSlides1].label}
                                    />
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography>{slides1[activeSlides1].label}</Typography>
                                    </Paper>
                                    <MobileStepper
                                        steps={maxSlides1}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides1}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides1} disabled={activeSlides1 === maxSlides1 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides1} disabled={activeSlides1 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>石和温泉是山梨县规模最大的温泉，</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>每分钟喷出的温泉多达2000公升。</p>
                                        <p>建议您先到车站前面的足汤泡泡脚，</p>
                                        <p>体验一下温泉的疗癒效果和肌肤变光滑的感觉。!</p>
                                        <p>足汤本身也是拍照打卡的好地方哦！</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides2[activeSlides2].imgPath}
                                        alt={slides2[activeSlides2].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides2}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides2}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides2} disabled={activeSlides2 === maxSlides2 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides2} disabled={activeSlides2 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>富士山可乐</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>足汤旁边的自动贩卖机有当地限定的「富士山可乐」！</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides3[activeSlides3].imgPath}
                                        alt={slides3[activeSlides3].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides3}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides3}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides3} disabled={activeSlides3 === maxSlides3 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides3} disabled={activeSlides3 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>おすすめ! もっと温泉体験 </span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>石和温泉为单纯硷性泉质，既可恢复疲劳，对神经痛和筋骨酸痛、手脚冰冷等症状也具有缓和效果。</p>
                                        <Button
                                            href="#moreFootSpa"
                                            variant="contained"
                                            endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                        >其他足汤</Button>
                                        <Button
                                            href="#lodgingInThisTown"
                                            variant="contained"
                                            endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                        >在本地住宿</Button>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides4[activeSlides4].imgPath}
                                        alt={slides4[activeSlides4].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides4}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides4}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides4} disabled={activeSlides4 === maxSlides4 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides4} disabled={activeSlides4 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem} id="moreFootSpa">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>石和源泉足汤广场</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>徒步15分钟</p>
                                        <p>有三座足汤和一座手汤。</p>
                                        <p>入场费加毛巾只要200日圆。</p>
                                        <p>从手脚慢慢暖到心房的温泉效果。（有临时休息日）</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides5[activeSlides5].imgPath}
                                        alt={slides5[activeSlides5].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides5}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides5}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides5} disabled={activeSlides5 === maxSlides5 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides5} disabled={activeSlides5 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem} id="lodgingInThisTown">
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>车站附近的住宿</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>找个地方歇个一晚，好好享受纯朴自然的石和温泉。</p>
                                        <p>全身泡在温泉里的畅快，尽在不言中。为您的旅途增添难忘的一页吧。</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides6[activeSlides6].imgPath}
                                        alt={slides6[activeSlides6].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides6}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides6}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides6} disabled={activeSlides6 === maxSlides6 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides6} disabled={activeSlides6 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </section>
                </Box>
            </Container>
            {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
        </Layout>
    );
}

export const query = graphql`
  {
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hiroba1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/hiroba-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hiroba2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/hiroba-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hotel1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/hotel-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hotel2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/hotel-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onsen1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/onsen-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onsen2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/onsen-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    towel:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/ashiyu-hand-towel.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bending:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/bending-machine.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cola:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/fujisan-cola.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ashiyu0:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/ashiyu-0.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ashiyu1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/ashiyu-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ashiyu2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/ashiyu-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ashiyu3:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/ashiyu-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D0:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/direction/0.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/direction/1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/direction/2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D3:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/direction/3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D4:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/direction/4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D5:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/onsen/ashiyu/direction/5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IsawaonsenStationAshiyuPage